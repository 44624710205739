$master: 16px;
/* Everything scales off of this! */
$background: #fff;
$cardbackground: #fffdfb;
$cardhoverbackground: #fff;
$color: #111;
$margin: ($master / 2) 0 ($master + ($master / 2)) 0;
$shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
$hovershadow: 0 4px 6px rgba(0, 0, 0, 0.2);
$btnColor: #ffc72c;
$pencil-green: #ffc72c;
$pencil-dark: #232123;
$anim-duration: 1.2; // in seconds
$anim-speed: 2.3; // in seconds

// html, body {
// 	background: $background;
// 	height: 100%;
// 	margin: 0;
// 	overflow-x: hidden;
// 	width: 100%;
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  font-family: "Quicksand", sans-serif;
  letter-spacing: 1px;
  --btn-border: 1px solid rgba(255, 255, 255, 0.2);
  --btn-bg: transparent; // #333
  --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 0.5);
  --btn-text-color: black;

  --shine-degree: 180deg;
  --shine-color: rgba(255, 255, 255, 1.8);
  --shine-effect: linear-gradient(
    var(--shine-degree),
    transparent,
    var(--shine-color),
    transparent
  );
  --shine-transition: all #{$anim-speed}s ease-in-out;
}

h1 {
  margin: 5px 0;
}

.impress-text {
  text-align: left;
  margin: 0;
}

.card {
  height: 1000px;
  background: $cardbackground;
  border-radius: 0.15em;
  font-size: $master;
  position: relative;
  text-align: center;
  font-family: "Quicksand";
  overflow: hidden;
  padding-top: 20px;

  .reviewContainer {
	width: 100%;
	min-height: 32rem;
	display: flex;
	flex-direction: column;

    .form__containers {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-size: 18px;
      }

      .single_line {
        width: 300px;
        height: 1px;
        background-color: #303030;
        margin-bottom: 10px;
      }

      .keywordcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .single_keyword {
          display: flex;
          flex-direction: row;
          cursor: pointer;

          span {
            margin-left: 7px;
            background-color: #fff;
            color: black;
            border-radius: 16px;
            box-shadow: $shadow;
            padding: 5px 10px;
            margin-bottom: 1rem;
            font-size: 16px;
          }
        }

        .disabled-keyword {
          pointer-events: none;

          span {
            color: rgb(131, 130, 130);
          }
        }

        .selected-keyword {
          span {
            background-color: $btnColor;
          }
        }
      }
    }

    .btn__container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .copy {
        background-color: $btnColor;
        color: #000000;
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 18px;
      }

      .skip {
        background-color: #fff;
        color: $btnColor;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 14px;
        border: none;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    // width: 100%;
    // height: 100vh;

    .add-box {
      width: 100%;
      overflow: scroll;
    }
  }

  &:hover {
    // background: $cardhoverbackground;
    // box-shadow: $hovershadow;
  }

  h1 {
    color: $color;
    font-size: ($master + ($master / 2));
    font-weight: 400;
    // margin: $margin;
    text-align: center;
  }

  p {
    color: lighten($color, 12%);
    font-weight: 300;
    line-height: ($master + ($master / 2));
    // margin: $margin;
    text-align: center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    10% {
      transform: rotate(0deg);
    }

    20% {
      transform: rotate(90deg);
    }

    35% {
      transform: rotate(90deg);
    }

    45% {
      transform: rotate(180deg);
    }

    60% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(270deg);
    }

    85% {
      transform: rotate(270deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    10% {
      -webkit-transform: rotate(0deg);
    }

    20% {
      -webkit-transform: rotate(90deg);
    }

    35% {
      -webkit-transform: rotate(90deg);
    }

    45% {
      -webkit-transform: rotate(180deg);
    }

    60% {
      -webkit-transform: rotate(180deg);
    }

    75% {
      -webkit-transform: rotate(270deg);
    }

    85% {
      -webkit-transform: rotate(270deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes bounce {
    0% {
      transform: scaleX(1);
    }

    10% {
      transform: scaleX(1);
    }

    20% {
      transform: scaleX(0.8);
    }

    35% {
      transform: scaleX(0.8);
    }

    45% {
      transform: scaleX(1);
    }

    60% {
      transform: scaleX(1);
    }

    75% {
      transform: scaleX(0.8);
    }

    85% {
      transform: scaleX(0.8);
    }

    100% {
      transform: scaleX(1);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      -webkit-transform: scaleX(1);
    }

    10% {
      -webkit-transform: scaleX(1);
    }

    20% {
      -webkit-transform: scaleX(0.8);
    }

    35% {
      -webkit-transform: scaleX(0.8);
    }

    45% {
      -webkit-transform: scaleX(1);
    }

    60% {
      -webkit-transform: scaleX(1);
    }

    75% {
      -webkit-transform: scaleX(0.8);
    }

    85% {
      -webkit-transform: scaleX(0.8);
    }

    100% {
      -webkit-transform: scaleX(1);
    }
  }

  .loader {
    box-sizing: content-box;
    height: 40px;
    margin: 0 auto 10px auto;
    position: relative;
    width: 70px;

    &:before {
      border: 2px solid $color;
      box-sizing: content-box;
      content: "";
      height: 0;
      left: 50%;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 0;
    }

    .spin {
      -webkit-animation: spin 2.25s linear infinite;
      animation: spin 2.25s linear infinite;
      height: 14px;
      left: 28px;
      position: absolute;
      top: 13px;
      width: 14px;

      &:before {
        border: 3px solid $color;
        box-sizing: content-box;
        content: "";
        height: 14px;
        left: 50%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
        width: 14px;
      }
    }

    .bounce {
      -webkit-animation: bounce 2.25s linear infinite;
      animation: bounce 2.25s linear infinite;
      height: 100%;
      margin: 0 auto;
      position: relative;
      width: 100%;

      &:after,
      &:before {
        box-sizing: content-box;
        content: "";
        height: 10px;
        position: absolute;
        top: 13px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 10px;
      }

      &:before {
        border-bottom: 3px solid $color;
        border-left: 3px solid $color;
        left: 0;
      }

      &:after {
        border-right: 3px solid $color;
        border-top: 3px solid $color;
        right: 0;
      }
    }
  }
}

.redirecttext {
  position: absolute;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  left: 45%;
  top: 70%;
  margin-left: -20px;

  @media screen and (max-width: 600px) {
    left: 35%;
  }

  @media screen and (max-width: 376px) {
    left: 35%;
  }
}

.loaderbody {
  position: absolute;
  top: 63%;
  margin-left: -50px;
  left: 45%;
  animation: speeder 0.4s linear infinite;

  > span {
    height: 5px;
    width: 35px;
    background: #000;
    position: absolute;
    top: -19px;
    left: 60px;
    border-radius: 2px 10px 1px 0;
  }
}

.base {
  span {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 100px solid #000;
    border-bottom: 6px solid transparent;

    &:before {
      content: "";
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background: #000;
      position: absolute;
      right: -110px;
      top: -16px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 0 solid transparent;
      border-right: 55px solid #000;
      border-bottom: 16px solid transparent;
      top: -16px;
      right: -98px;
    }
  }
}

.face {
  position: absolute;
  height: 12px;
  width: 20px;
  background: #000;
  border-radius: 20px 20px 0 0;
  transform: rotate(-40deg);
  right: -125px;
  top: -15px;

  &:after {
    content: "";
    height: 12px;
    width: 12px;
    background: #000;
    right: 4px;
    top: 7px;
    position: absolute;
    transform: rotate(40deg);
    transform-origin: 50% 50%;
    border-radius: 0 0 0 2px;
  }
}

.loaderbody > span > span:nth-child(1),
.loaderbody > span > span:nth-child(2),
.loaderbody > span > span:nth-child(3),
.loaderbody > span > span:nth-child(4) {
  width: 30px;
  height: 1px;
  background: #000;
  position: absolute;
  animation: fazer1 0.2s linear infinite;
}

.loaderbody > span > span:nth-child(2) {
  top: 3px;
  animation: fazer2 0.4s linear infinite;
}

.loaderbody > span > span:nth-child(3) {
  top: 1px;
  animation: fazer3 0.4s linear infinite;
  animation-delay: -1s;
}

.loaderbody > span > span:nth-child(4) {
  top: 4px;
  animation: fazer4 1s linear infinite;
  animation-delay: -1s;
}

@keyframes fazer1 {
  0% {
    left: 0;
  }

  100% {
    left: -80px;
    opacity: 0;
  }
}

@keyframes fazer2 {
  0% {
    left: 0;
  }

  100% {
    left: -100px;
    opacity: 0;
  }
}

@keyframes fazer3 {
  0% {
    left: 0;
  }

  100% {
    left: -50px;
    opacity: 0;
  }
}

@keyframes fazer4 {
  0% {
    left: 0;
  }

  100% {
    left: -150px;
    opacity: 0;
  }
}

@keyframes speeder {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }

  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }

  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.longfazers {
  position: absolute;
  width: 100%;
  height: 100%;

  span {
    position: absolute;
    height: 2px;
    width: 20%;
    background: #000;

    &:nth-child(1) {
      top: 20%;
      animation: lf 0.6s linear infinite;
      animation-delay: -5s;
    }

    &:nth-child(2) {
      top: 40%;
      animation: lf2 0.8s linear infinite;
      animation-delay: -1s;
    }

    &:nth-child(3) {
      top: 60%;
      animation: lf3 0.6s linear infinite;
    }

    &:nth-child(4) {
      top: 80%;
      animation: lf4 0.5s linear infinite;
      animation-delay: -3s;
    }
  }
}

@keyframes lf {
  0% {
    left: 200%;
  }

  100% {
    left: -200%;
    opacity: 0;
  }
}

@keyframes lf2 {
  0% {
    left: 200%;
  }

  100% {
    left: -200%;
    opacity: 0;
  }
}

@keyframes lf3 {
  0% {
    left: 200%;
  }

  100% {
    left: -100%;
    opacity: 0;
  }
}

@keyframes lf4 {
  0% {
    left: 200%;
  }

  100% {
    left: -100%;
    opacity: 0;
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 5px;
  height: 5px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-5px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.pencil {
  @keyframes pencil-animation {
    0% {
      transform: rotate(135deg);
    }

    20% {
      transform: rotate(315deg);
    }

    45% {
      transform: translateX(300px) rotate(315deg);
    }

    55% {
      transform: translateX(300px) rotate(495deg);
    }

    100% {
      transform: rotate(495deg);
    }
  }

  position: relative;
  width: 49px;
  height: 7px;
  transform-origin: center;
  transform: rotate(110deg);
  animation: pencil-animation 2s infinite;

  &__ball-point {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $pencil-green;
    height: 10px;
    width: 10px;
    border-radius: 50px;
  }

  &__cap {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    clip-path: polygon(20% 40%, 100% 0%, 100% 100%, 20% 60%);
    background: $pencil-dark;
    width: 12%;
    height: 100%;
  }

  &__cap-base {
    position: absolute;
    left: 12%;
    top: 0;
    height: 100%;
    width: 20px;
    background: $pencil-dark;
  }

  &__middle {
    position: absolute;
    left: calc(12% + 20px);
    top: 0;
    height: 100%;
    width: 70%;
    background: $pencil-green;
  }

  &__eraser {
    position: absolute;
    left: calc(12% + 70% + 20px);
    top: 0;
    height: 100%;
    width: 11%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: $pencil-dark;
  }
}

.line {
  @keyframes line-animation {
    20% {
      transform: scaleX(0);
    }

    45% {
      transform: scaleX(0.6);
    }

    55% {
      transform: scaleX(0.6);
    }

    100% {
      transform: scaleX(0);
    }
  }

  position: relative;
  // top: 80px;
  // right: 103px;
  height: 10px;
  // width: 120%;
  z-index: 1;
  border-radius: 50px;
  background: $pencil-green;
  transform: scaleX(0);
  transform-origin: center;
  animation: line-animation 2s infinite;
}

.modal-overlay {
  background-color: rgba(115, 115, 115, 0.617);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.blur-card {
  filter: blur(8px);
}

.add-box {
  margin: 50px auto;
  border-top: 25px solid rgb(115, 112, 112);
  border-bottom: 25px solid rgb(115, 112, 112);
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100vw;
}

.adsbygoogle {
  width: 90% !important;
}

.add-box-small {
  width: 100%;
  height: 100px;
  margin-top: 20px;
}

.btm-shine {
  position: relative;
  overflow: hidden; // debug
  background-color: #ffcc00;

  // last element should not have margin
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  font-size: 1.1rem;
  padding: 1rem 3rem;

  border-radius: 4px;
  text-decoration: none;
  border: var(--btn-border);
  color: var(--btn-text-color);
  background: #ffcc00;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--shine-effect);
  }
}

.btn-hover {
  &::before {
    left: -100%;
    transition: var(--shine-transition);
  }

  &:hover {
    box-shadow: var(--btn-shadow);

    &::before {
      left: 100%;
    }
  }
}

.btn-anim {
  &::before {
    animation: shine #{$anim-duration}s ease-in-out infinite;
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }

  #{($anim-speed / ($anim-duration + $anim-speed) * 100%)},
  100% {
    left: 100%;
    transition-property: left;
  }
}

.btn-alt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  width: 85%;
  margin: auto;
  border-radius: 11px !important;
  position: relative;
  text-transform: capitalize;
  overflow: hidden; // debug

  // last element should not have margin
  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  border-radius: 4px;
  text-decoration: none;
  border: none;
  color: var(--btn-text-color);
  background: var(--btn-bg);

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
  }

  &:before {
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    left: 0;
    filter: blur(30px);
    animation: shine-alt1 #{$anim-duration}s ease-in-out infinite;
  }

  &:after {
    background: rgba(255, 255, 255, 0.5);
    width: 30px;
    left: 30px;
    filter: blur(5px);
    animation: shine-alt2 #{$anim-duration}s ease-in-out infinite;
  }
}

@keyframes shine-alt1 {
  0% {
    opacity: 0.5;
    transform: translateX(-100px) skewX(-55deg);
    transition-property: opacity, transform;
  }

  #{($anim-speed / ($anim-duration + $anim-speed) * 100%)},
  100% {
    opacity: 0.8;
    transform: translateX(300px) skewX(-55deg);
    transition-property: opacity, transform;
  }
}

@keyframes shine-alt2 {
  0% {
    opacity: 0;
    transform: translateX(-100px) skewX(-35deg);
    transition-property: opacity, transform;
  }

  #{($anim-speed / ($anim-duration + $anim-speed) * 100%)},
  100% {
    opacity: 1;
    transform: translateX(300px) skewX(-35deg);
    transition-property: opacity, transform;
  }
}

.image-logo {
  height: 41px;
  margin: -11px 5px;
  position: absolute;
  left: -5px;
}

.progress-parent {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 0 0 26px 0;
  position: relative;
}

.progress-child {
  flex: 1;
  flex-direction: row;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    border: 3px solid #ff6900;
    margin: auto;
    border-radius: 22px;
    position: relative;
    background-color: #ff6900;
    color: #fff;
    font-weight: bolder;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8.3px;
      height: 8.3px;
      // padding: 5px 5px 5px 5px;
    }
  }

  .active {
    background-color: #fff;
    color: #ff6900;
    text-align: center;
    font-weight: bold;
  }

  .completed {
    font-size: xx-large;
    text-align: center;
    font-weight: 800;
    display: grid;
    place-items: center;
  }

  div {
    height: 22px;
    border-bottom: 2px solid;
  }
}

.progress-line {
  position: absolute;
  width: 73%;
  height: 3px;
  background-color: #ff6900;
  top: 17px;

  @media screen and (min-width: 590px) {
    width: 70%;
  }

  @media screen and (min-width: 1060px) {
    width: 65%;
  }
}

.pen-loader {
  height: 120px;
  margin: 30px auto 0 auto;
  width: 85%;
}

.review-text {
  width: 82%;
  background-color: $cardbackground;
  border-radius: 5px;
  padding: 5px;
  font-family: "Quicksand";
  margin: 5px 0 0px 0;
  font-size: 18px;
  height: 100px;
}

.paste-text {
  width: 100%;
  font-family: "Quicksand";
  font-size: 18px;
}

.paste-sub-text {
  width: 100%;
  font-size: 11px;
  margin: 10px 0 20px 0;
  font-family: "Quicksand";
}

.hr-line {
  width: 80%;
  height: 2px;
  background-color: #ff6900;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
}

.copy-image {
  width: 34px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #fff;
  padding: 5px;
  border: none;
  z-index: 9999;
  outline: none;
}

.multi-button {
  flex-direction: row !important;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;
  // height: 40px;
}
.social-icons {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 25px;
}

.loader {
  color: black;
  aspect-ratio: 1/1;
  border-radius: 50%;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 20px;
  border: 5px dotted #fff;
  animation-duration: 5s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.paste-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
